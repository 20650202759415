import {withdrawalStore} from "../../config/firebase";
import globalObjects from "../../utils/globalObjects";
import Withdrawal from "../../models/withdrawal";

export default {
    namespaced: true,
    state: {
        withdrawals: [],
        allWithdrawals: [],
        withdrawals_listener: null,
        allWithdrawals_listener: null
    },
    getters: {
        getWithdrawals: state => state.withdrawals,
        getAllWithdrawals: state => state.allWithdrawals,
    },
    mutations: {
        setWithdrawals: (state, payload) => state.withdrawals = payload,
        setAllWithdrawals: (state, payload) => state.allWithdrawals = payload,
        reset(state){
            if(state.allWithdrawals_listener!==null) state.allWithdrawals_listener();
            if(state.withdrawals_listener!==null) state.withdrawals_listener();
            state.allWithdrawals_listener = null;
            state.withdrawals_listener = null;
            state.withdrawals = [];
            state.allWithdrawals = []
        }
    },
    actions: {
        fetchAllWithdrawals({state, commit}){
            state.allWithdrawals_listener = withdrawalStore
                .orderBy('createdAt', 'desc')
                .onSnapshot(snapshot => {
                    const tmp_arr = [];
                    snapshot.forEach(doc=>{
                        if(doc.exists){
                            tmp_arr.push({
                                id: doc.id, data: doc.data()
                            })
                        }
                    });
                    commit('setAllWithdrawals', tmp_arr)
                })
        },
        fetchWithdrawals({state, commit, rootGetters}){
            state.withdrawals_listener = withdrawalStore
                .orderBy('createdAt', 'desc')
                .where('user', '==', rootGetters['user/getUser'].id)
                .onSnapshot(snapshot => {
                    const tmp_arr = [];
                    snapshot.forEach(doc=>{
                        if(doc.exists){
                            tmp_arr.push({
                                id: doc.id, data: doc.data()
                            })
                        }
                    });
                    commit('setWithdrawals', tmp_arr)
                })
        },
        async addWithdrawal({rootGetters}, withdrawal){
            const response = new globalObjects.CustomResponse();
            try{
                const new_withdrawal = new Withdrawal(withdrawal);
                new_withdrawal.data.user = rootGetters['user/getUser'].id;
                await withdrawalStore.add(Object.assign({}, new_withdrawal.data))
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response);
        },
        async toggleWithdrawalStatus({dispatch}, {withdrawal, status}){
            const response = new globalObjects.CustomResponse();
            try{
                const tmp_withdrawal = new Withdrawal(withdrawal.data, withdrawal.id);
                await withdrawalStore.doc(tmp_withdrawal.id).update({status});
                if(status === 'APPROVED'){
                    const _user = await dispatch('user/getUser', tmp_withdrawal.data.user, {root: true});
                    if(_user.status){
                        await dispatch('user/updateUser', {
                            userId: _user.data.id,
                            userData: {balance: Math.max(0, (_user.data.data.balance-tmp_withdrawal.data.amount))}
                        }, {root: true})
                    }
                }
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response);
        }
    },
}