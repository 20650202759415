import {firestore} from "../config/firebase";

class Contact{
    constructor(contact, contact_id) {
        this.id = contact_id;
        this.data = Object.assign(new Model(), contact)
    }
}

function Model(){
    this.name  ="";
    this.email = "";
    this.subject = "";
    this.message = "";
    this.createdAt = firestore.FieldValue.serverTimestamp()
}

export default Contact