import GlobalObjects from "../../utils/globalObjects"
import firebase,{userStore} from "../../config/firebase";
import router from "../../router";
// import GlobalFunctions from "../../utils/globalFunctions";

export default {
    namespaced: true,
    state: {
        loggedIn: false
    },
    getters: {
        isLoggedIn(state){
            firebase.auth().onAuthStateChanged(user=>state.loggedIn = !!user)
            return state.loggedIn;
        }
    },
    mutations: {

    },
    actions: {
        async register({dispatch}, {user, password}){
            let response = new GlobalObjects.CustomResponse();
            try{
                await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
                let auth_result = await firebase.auth().createUserWithEmailAndPassword(user.email, password);
                await userStore
                    .doc(auth_result.user.uid)
                    .set(Object.assign({}, user));
                await dispatch('user/initUser', auth_result.user.uid, {root: true});
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async login({dispatch}, {email, password}){
            const response = new GlobalObjects.CustomResponse();
            try{
                await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
                const u_credential = await firebase.auth().signInWithEmailAndPassword(email, password);
                const user = await dispatch('user/getUser', u_credential.user.uid, {root: true});
                if(user.status){
                    await dispatch('user/initUser', u_credential.user.uid, {root: true})
                }else{
                    response.set_status(false, new Error("Unable to fetch account info"))
                }
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async resetPassword(context, email){
            let response = new GlobalObjects.CustomResponse();
            try{
                await firebase.auth().sendPasswordResetEmail(email)
            }catch (e){
                response.set_status(false, e);
            }
            return Promise.resolve(response)
        },
        async updatePassword(context, {email, password}){
            const response = new GlobalObjects.CustomResponse();
            try{
                const _response = await GlobalObjects.TEInstance
                    .post('user/update-password', {email,password});
                response.data = _response.data;
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async logout({commit}){
            try{
                await firebase.auth().signOut();
                await router.push({name: 'Login'});
            }catch (e){
                console.log(e)
            }
            commit('bonus/reset', null, {root: true});
            commit('deposit/reset', null, {root: true});
            commit('transfer/reset', null, {root: true});
            commit('user/reset', null, {root: true});
            commit('wallet/reset', null, {root: true});
            commit('withdrawal/reset', null, {root: true});
            commit('contact/reset', null, {root: true});

        }
    }
}