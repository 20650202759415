import {depositStore, firestore} from "@/config/firebase";
import Deposit from "../../models/deposit";
import globalObjects from "../../utils/globalObjects";
import User from "../../models/user";

export default {
    namespaced: true,
    state: {
        deposits: [],
        listener: null,
        all_deposits: [],
        all_listener: null
    },
    getters: {
        getDeposits: state => state.deposits,
        getAllDeposits: state => state.all_deposits
    },
    mutations: {
        setDeposits: (state, payload) => state.deposits  =payload,
        setAllDeposits: (state, payload) => state.all_deposits = payload,
        reset(state){
            if(state.listener !== null) state.listener();
            if(state.all_listener !== null) state.all_listener();
            state.all_deposits = state.deposits = [];
            state.listener = state.all_listener = null;
        }
    },
    actions: {
        getAllDeposits({state, commit}){
            state.listener = depositStore
                .orderBy('createdAt', 'desc')
                .onSnapshot(snapshot => {
                    const tmp_arr = [];
                    snapshot.forEach(doc=>{
                        if(doc.exists){
                            tmp_arr.push({
                                id: doc.id,
                                data: doc.data()
                            })
                        }
                    });
                    commit('setAllDeposits', tmp_arr)
                })
        },
        getDeposits({state, commit, rootGetters}){
            state.listener = depositStore
                .orderBy('createdAt', 'desc')
                .where('user', '==', rootGetters['user/getUser'].id)
                .onSnapshot(snapshot => {
                    const tmp_arr = [];
                    snapshot.forEach(doc=>{
                        if(doc.exists){
                            tmp_arr.push({
                                id: doc.id,
                                data: doc.data()
                            })
                        }
                    });
                    commit('setDeposits', tmp_arr)
                })
        },
        async addDeposit({dispatch, rootGetters}, deposit){
            const response = new globalObjects.CustomResponse();
            response.data = '';
            try{
                const new_deposit = new Deposit(deposit);
                new_deposit.data.user = rootGetters['user/getUser'].id;
                if(new_deposit.data.paidThrough === 'BALANCE'){
                    new_deposit.data.status = 'IN-PROGRESS'
                }else{
                    let tmp_response = await dispatch('wallet/getAddress',null, {root: true});
                    if(tmp_response.status){
                        new_deposit.data.walletAddress = tmp_response.data.address;
                        new_deposit.data.walletAddressOwner = tmp_response.data.createdBy;
                        response.data = tmp_response.data.address;
                    }else{
                        throw new Error("Unable to process information")
                    }
                }
                await depositStore.add(Object.assign({}, new_deposit.data));

                if(new_deposit.data.paidThrough === 'BALANCE'){
                    const user =  await dispatch('user/getUser', rootGetters['user/getUser'].id, {root: true});
                    if(user.status){
                        const usr = new User(user.data.data, user.data.id);
                        usr.data.balance = Math.max(0, (usr.data.balance-new_deposit.data.amountDeposited))
                        await dispatch('user/updateUser', {
                            userId: usr.id,
                            userData: {balance: usr.data.balance}
                        }, {root: true});
                        await dispatch('user/creditReferralBonus', new_deposit, {root: true});
                    }
                }

            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async updateDeposit(context, deposit){
            const response = new globalObjects.CustomResponse();
            response.data = '';
            try{
                const tmp_deposit = new Deposit(deposit.data, deposit.id);
                delete tmp_deposit.data.createdAt;
                tmp_deposit.data.updatedAt = firestore.FieldValue.serverTimestamp();
                await depositStore
                    .doc(tmp_deposit.id)
                    .update(Object.assign({}, tmp_deposit.data));
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async confirmDeposit({dispatch, rootGetters}, deposit){
            const response = new globalObjects.CustomResponse();
            response.data = '';
            try{
                const tmp_deposit = new Deposit(deposit.data, deposit.id);
                await depositStore
                    .doc(tmp_deposit.id)
                    .update({
                        status: 'IN-PROGRESS'
                    });
                const user =  await dispatch('user/getUser', rootGetters['user/getUser'].id, {root: true});
                if(user.status){
                    const usr = new User(user.data.data, user.data.id);
                    usr.data.balance = Math.max(0, (usr.data.balance-tmp_deposit.data.amountDeposited))
                    await dispatch('user/updateUser', {
                        userId: usr.id,
                        userData: {balance: usr.data.balance}
                    }, {root: true});
                    await dispatch('user/creditReferralBonus', tmp_deposit, {root: true});
                }
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async autoRunInterest({rootGetters}){
            const response = new globalObjects.CustomResponse();
            try{
                const runningInterest = await depositStore
                    .where('user', '==', rootGetters['user/getUser'].id)
                    .where('status', '==', 'IN-PROGRESS')
                    .get();
                if(!runningInterest.empty){
                    const promises = [];
                    runningInterest.forEach(doc=>{
                        if(doc.exists){
                            promises.push(new Deposit(doc.data(), doc.id).autoTrade())
                        }
                    });
                    await Promise.all(promises)
                }
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        }
    }
}