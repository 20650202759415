class Plans{

    static get all(){
        return {
            BITCOIN: {
                'BASIC': {
                    dailyProfit: 0.83,  // percentage
                    minDeposit: 500,
                    maxDeposit: 4999,
                    lifeSpan: 1,     // Months
                    referralBonus: 7,    // percentage
                    withdrawal: 'WEEKLY',
                    accumulation: {
                        status: false,
                        frequency: 0   // DAYS
                    },
                },
                'PRIME': {
                    dailyProfit: 1,  // percentage
                    minDeposit: 5000,
                    maxDeposit: 9999,
                    lifeSpan: 5,     // Months
                    referralBonus: 5,    // percentage
                    withdrawal: 'WEEKLY',
                    accumulation: {
                        status: false,
                        frequency: 0   // DAYS
                    },
                },
                'EXECUTIVE': {
                    dailyProfit: 1.52,  // percentage
                    minDeposit: 50000,
                    maxDeposit: 99999,
                    lifeSpan: 12,     // Months
                    referralBonus: 5,    // percentage
                    withdrawal: 'WEEKLY',
                    accumulation: {
                        status: false,
                        frequency: 0   // DAYS
                    },
                },
                'CONTRACT': {
                    dailyProfit: 1.87,  // percentage
                    minDeposit: 10000,
                    maxDeposit: 1000000,
                    lifeSpan: 6,     // Months
                    referralBonus: 20,    // percentage
                    withdrawal: 'WEEKLY',   // MONTHLY, WEEKLY,
                    accumulation: {
                        status: false,
                        frequency: 0   // DAYS
                    },
                },
                'BUSINESS': {
                    dailyProfit: 2.2,  // percentage
                    minDeposit: 100000,
                    maxDeposit: 1000000,
                    lifeSpan: 96,     // Months
                    referralBonus: 25,    // percentage
                    withdrawal: 'WEEKLY',
                    accumulation: {
                        status: false,
                        frequency: 0   // DAYS
                    },
                },
                'STANDARD': {
                    dailyProfit: 1.2,  // percentage
                    minDeposit: 10000,
                    maxDeposit: 49999,
                    lifeSpan: 4,     // Months
                    referralBonus: 7,    // percentage
                    withdrawal: 'WEEKLY',
                    accumulation: {
                        status: false,
                        frequency: 0   // DAYS
                    },
                }
            },
            ETHEREUM: {
                'BASIC': {
                    dailyProfit: 0.73,  // percentage
                    minDeposit: 500,
                    maxDeposit: 4999,
                    lifeSpan: 3,     // Months
                    referralBonus: 6,    // percentage
                    withdrawal: 'WEEKLY',
                    accumulation: {
                        status: false,
                        frequency: 0   // DAYS
                    },
                },
                'PRIME': {
                    dailyProfit: 0.9,  // percentage
                    minDeposit: 5000,
                    maxDeposit: 9999,
                    lifeSpan: 2,     // Months
                    referralBonus: 4,    // percentage
                    withdrawal: 'WEEKLY',
                    accumulation: {
                        status: false,
                        frequency: 0   // DAYS
                    },
                },
                'EXECUTIVE': {
                    dailyProfit: 1.4,  // percentage
                    minDeposit: 50000,
                    maxDeposit: 99999,
                    lifeSpan: 12,     // Months
                    referralBonus: 4,    // percentage
                    withdrawal: 'WEEKLY',
                    accumulation: {
                        status: false,
                        frequency: 0   // DAYS
                    },
                },
                'CONTRACT': {
                    dailyProfit: 1.7,  // percentage
                    minDeposit: 10000,
                    maxDeposit: 1000000,
                    lifeSpan: 6,     // Months
                    referralBonus: 19,    // percentage
                    withdrawal: 'WEEKLY',   // MONTHLY, WEEKLY,
                    accumulation: {
                        status: false,
                        frequency: 0   // DAYS
                    },
                },
                'BUSINESS': {
                    dailyProfit: 2,  // percentage
                    minDeposit: 100000,
                    maxDeposit: 1000000,
                    lifeSpan: 96,     // Months
                    referralBonus: 24,    // percentage
                    withdrawal: 'WEEKLY',
                    accumulation: {
                        status: false,
                        frequency: 0   // DAYS
                    },
                },
                'STANDARD': {
                    dailyProfit: 1,  // percentage
                    minDeposit: 10000,
                    maxDeposit: 49999,
                    lifeSpan: 6,     // Months
                    referralBonus: 6,    // percentage
                    withdrawal: 'WEEKLY',
                    accumulation: {
                        status: false,
                        frequency: 0   // DAYS
                    },
                }
            },
            GOLD: {
                'GOLD COIN': {
                    dailyProfit: 0.57,  // percentage
                    minDeposit: 800,
                    maxDeposit: 1700,
                    lifeSpan: 0,     // Months
                    referralBonus: 2,    // percentage
                    withdrawal: 'DAILY',
                    accumulation: {
                        status: true,
                        frequency: 14   // DAYS
                    }
                },
                '100GM GOLD BAR': {
                    dailyProfit: 0.6,  // percentage
                    minDeposit: 2950,
                    maxDeposit: 5900,
                    lifeSpan: 0,     // Months
                    referralBonus: 4,    // percentage
                    withdrawal: 'MONTHLY',
                    accumulation: {
                        status: true,
                        frequency: 30   // DAYS
                    },
                },
                '50Z GOLD BAR': {
                    dailyProfit: 0.73,  // percentage
                    minDeposit: 4700,
                    maxDeposit: 9400,
                    lifeSpan: 0,     // Months
                    referralBonus: 5,    // percentage
                    withdrawal: 'MONTHLY',
                    accumulation: {
                        status: true,
                        frequency: 30   // DAYS
                    },
                },
                '250G GOLD BAR': {
                    dailyProfit: 0.83,  // percentage
                    minDeposit: 7450,
                    maxDeposit: 14900,
                    lifeSpan: 0,     // Months
                    referralBonus: 10,    // percentage
                    withdrawal: 'MONTHLY',   // MONTHLY, WEEKLY
                    accumulation: {
                        status: true,
                        frequency: 30   // DAYS
                    },
                },
                '500G GOLD BAR': {
                    dailyProfit: 1,  // percentage
                    minDeposit: 14500,
                    maxDeposit: 29000,
                    lifeSpan: 0,     // Months
                    referralBonus: 8,    // percentage
                    withdrawal: 'MONTHLY',
                    accumulation: {
                        status: true,
                        frequency: 30   // DAYS
                    }
                },
                '1KG GOLD BAR': {
                    dailyProfit: 1.33,  // percentage
                    minDeposit: 27000,
                    maxDeposit: 57000,
                    lifeSpan: 0,     // Months
                    referralBonus: 6,    // percentage
                    withdrawal: 'MONTHLY',
                    accumulation: {
                        status: true,
                        frequency: 30   // DAYS
                    }
                }
            }
        }
    }
}

export default Plans