import GlobalFunctions from "./globalFunctions";

class Plugins{
    // eslint-disable-next-line no-unused-vars
    static install(Vue, options={}){
        Vue.prototype.$globalFunc = GlobalFunctions;

    }
}

export default Plugins