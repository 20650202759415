import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

import auth from "./modules/auth";
import user from "./modules/user";
import deposit from "./modules/deposit";
import wallet from "./modules/wallet";
import bonus from "./modules/bonus";
import withdrawal from "./modules/withdrawal";
import transfer from "./modules/transfer";
import contact from "./modules/contact";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    key: 'trustearns',
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    },
  })],
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    user,
    deposit,
    wallet,
    bonus,
    withdrawal,
    transfer,
    contact
  }
})
