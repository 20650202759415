import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"


const production = window.location.hostname === 'trustearns.com' || window.location.hostname === 'www.trustearns.com';
let firebaseConfig;

if(production){
    firebaseConfig = {
        apiKey: "AIzaSyAdI0L-QIWoH5MUfA5N1BtoLgWO9oOT96U",
        authDomain: "trustearns-34802.firebaseapp.com",
        projectId: "trustearns-34802",
        storageBucket: "trustearns-34802.appspot.com",
        messagingSenderId: "340216893022",
        appId: "1:340216893022:web:7ac980c225b5f91a3b6ecf",
        measurementId: "G-Y8TVE1P86P"
    }
}else{
    firebaseConfig = {
        apiKey: "AIzaSyBsDZxV-8pAoFPcefj51fui_7A0DZ6la0k",
        authDomain: "trustearns-dev.firebaseapp.com",
        projectId: "trustearns-dev",
        storageBucket: "trustearns-dev.appspot.com",
        messagingSenderId: "636782318163",
        appId: "1:636782318163:web:1aae782c07a5c724626ca6",
        measurementId: "G-0XW2LVKVW4"
    }
}

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore;
// export const storageRef = firebase.storage().ref();
export const userStore = firestore().collection("users")
export const walletStore = firestore().collection("wallets")
export const withdrawalStore = firestore().collection("withdrawals")
export const depositStore = firestore().collection("deposits")
export const contactStore = firestore().collection("contacts")
export const transferStore = firestore().collection("transfers")
export const bonusStore = firestore().collection("bonus")

export default firebase