import {firestore} from "../config/firebase";

class Transfer{
    constructor(transfer, transfer_id='') {
        this.id = transfer_id;
        this.data = Object.assign(new Model(), transfer)
    }
}

function Model(){
    this.user = "";
    this.receiver = "";
    this.amount = 0;
    this.createdAt = firestore.FieldValue.serverTimestamp()
}

export default Transfer