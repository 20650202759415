import {firestore} from "../config/firebase";

class Bonus{
    constructor(bonus={}, bonus_id='') {
        this.id = bonus_id;
        this.data = Object.assign(new Model(), bonus);

    }
}

function Model(){
    this.user = '';
    this.amount = 0;
    this.context = 'REFERRAL';
    this.metadata = {
        from: ''    // user-id
    };
    this.createdAt = firestore.FieldValue.serverTimestamp();
}

export default Bonus