import {firestore} from "../config/firebase";

class Withdrawal{
    constructor(withdraw, withdraw_id="") {
        this.id = withdraw_id;
        this.data = Object.assign(new Model(), withdraw)
    }
}

function Model(){
    this.user = '';
    this.amount = 0;
    this.wallet = "";
    this.cryptoID = "";
    this.createdAt = firestore.FieldValue.serverTimestamp();
    this.status = "PENDING";   // PENDING, APPROVED, DECLINED
}

export default Withdrawal