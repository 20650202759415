import {bonusStore} from "../../config/firebase";
import globalObjects from "../../utils/globalObjects";
import Bonus from "../../models/bonus";

export default {
    namespaced: true,
    state: {
        bonuses: [],
        listener: null
    },
    getters: {
        getBonuses: state  => state.bonuses
    },
    mutations: {
        setBonuses: (state, payload) => state.bonuses = payload,
        reset(state){
            if(state.listener !== null) state.listener();
            state.listener = null;
            state.bonuses = [];
        }
    },
    actions: {
        fetchBonuses({state, commit, rootGetters}){
            state.listener = bonusStore
                .where('user', '==', rootGetters['user/getUser'].id)
                .orderBy('createdAt', 'desc')
                .onSnapshot(snapshot => {
                    const tmp_arr = [];
                    snapshot.forEach(doc=>{
                        if(doc.exists){
                            tmp_arr.push({
                                id: doc.id, data: doc.data()
                            })
                        }
                    });
                    commit('setBonuses', tmp_arr)
                })
        },
        async addBonus(context, {user, amount}){
            const response = new globalObjects.CustomResponse();
            try{
                const new_bonus = new Bonus();
                new_bonus.data.user = user.data.referredBy;
                new_bonus.data.amount = amount;
                new_bonus.data.metadata.from = user.id;
                await bonusStore.add(Object.assign({}, new_bonus.data))
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        }
    }
}