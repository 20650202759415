import axios from "axios";

const error_messages = {
    'auth/email-already-in-use': "The email is already in use by another",
    'auth/invalid-email': "The email is not a valid email address",
    'auth/operation-not-allowed': "Unknown Error. Contact the System Admin",    // Thrown if email/password accounts are not enabled
    'auth/weak-password': 'The password is too weak',
    'cancelled': 'Operation cancelled',
    'unavailable': 'The service is currently not available',
    'unauthenticated': "Not enough valid credentials to continue operation",
    "auth/user-disabled": "You have been disabled. Contact the System Admin",
    "auth/user-not-found": "This account does not exist!",
    "auth/wrong-password": "Invalid email or password",
    "auth/requires-recent-login": "You have been logged in for quite a while now. Kindly logout and re-login to update your password"
}
class CustomResponse{
    constructor() {
        this.status = true;
        this._error = null;
        this.data = {};
    }

    get message(){
        return error_messages[this._error.code] || this._error.message || this._error
    }
    set_status(status, error=null){
        this.status = status;
        this._error = error;
    }
}

const LIVE_DEV_API_URL = 'https://us-central1-trustearns-dev.cloudfunctions.net/api/';
const LOCAL_DEV_API_URL = 'http://localhost:5001/trustearns-dev/us-central1/api/';
const LIVE_API_URL = 'https://us-central1-trustearns-34802.cloudfunctions.net/api/';
let API_URL;
const host = window.location.host;

switch (host){
    case 'trustearns-dev.web.app': {
        API_URL = LIVE_DEV_API_URL;
        break;
    }
    case "localhost:8080": {
        API_URL = LOCAL_DEV_API_URL;
        break;
    }
    case "www.trustearns.com": {
        API_URL = LIVE_API_URL;
        break;
    }
    case "trustearns.com": {
        API_URL = LIVE_API_URL;
        break;
    }
}

const TEInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'X-MW-Api-Key': '',
        'Access-Control-Allow-Origin': '*',
    },
});

export default {
    CustomResponse,
    TEInstance
}