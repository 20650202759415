import {firestore} from "../config/firebase";

class User{
    constructor(user={}, userId='') {
        this.id = userId;
        this.data = Object.assign(new Model(), user)
    }
}

function Model(){
    this.name = "";
    this.email = "";
    this.country = "";
    this.region = "";
    this.phone = "";
    this.balance = 0;
    this.currency = "USD";
    this.wallets = {};   // {<crypto-id>:[<address>,..], ..}
    this.referrals = [];
    this.referredBy = "";
    this.is_admin = false;
    this.password_reset_ref = "";
    this.createdAt = firestore.FieldValue.serverTimestamp();
}

export default User