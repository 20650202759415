import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Plugins from "./utils/plugins";
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import firebase from "./config/firebase";
import vueCountryRegionSelect from 'vue-country-region-select'
import VueQrcode from 'vue-qrcode'

Vue.config.productionTip = false

Vue.use(Plugins)
Vue.use(VueLoading)
Vue.use(vueCountryRegionSelect)
Vue.component('qrCode', VueQrcode)


let app;
firebase.auth().onAuthStateChanged(function(){
    if(!app){
        app = new Vue({
            router,
            store,
            render: h => h(App)
        }).$mount('#app')
    }
})
