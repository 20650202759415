import {transferStore} from "../../config/firebase";
import globalObjects from "../../utils/globalObjects";
import Transfer from "../../models/transfer";

export default {
    namespaced: true,
    state: {
        listener: null,
        transfers: []
    },
    getters: {
        getTransfers: state => state.transfers
    },
    mutations: {
        setTransfers: (state, payload) => state.transfers = payload,
        reset(state){
            if(state.listener !== null) state.listener();
            state.listener = null;
            state.transfers = []
        }
    },
    actions: {
        fetchTransfers({state, commit, rootGetters}){
            state.listener = transferStore
                .orderBy('createdAt', 'desc')
                .where('user', '==', rootGetters['user/getUser'].id)
                .onSnapshot(snapshot => {
                    const tmp_arr = [];
                    snapshot.forEach(doc=>{
                        if(doc.exists){
                            tmp_arr.push({
                                id: doc.id, data: doc.data()
                            })
                        }
                    });
                    commit('setTransfers', tmp_arr);
                })
        },
        async addTransfer({rootGetters, dispatch}, transfer){
            const response = new globalObjects.CustomResponse();
            try{
                const new_transfer = new Transfer(transfer);
                new_transfer.data.user = rootGetters['user/getUser'].id;
                const _user = await dispatch('user/getUser', rootGetters['user/getUser'].id, {root: true});
                let _receiver = await dispatch('user/getUserByEmail', new_transfer.data.receiver, {root: true});
                if(_receiver.status){
                    if(_user.status){
                        if(_user.data.data.balance >= new_transfer.data.amount){
                            new_transfer.data.receiver = _receiver.data.id;
                            await transferStore.add(Object.assign({}, new_transfer.data));
                            await dispatch('user/updateUser', {
                                userId: _user.data.id,
                                userData: {balance: (_user.data.data.balance-new_transfer.data.amount)}
                            }, {root: true})
                            await dispatch('user/updateUser', {
                                userId: _receiver.data.id,
                                userData: {balance: (_receiver.data.data.balance+new_transfer.data.amount)}
                            }, {root: true})
                        }else{
                            response.set_status(false, new Error("Insufficient account balance"))
                        }
                    }else{
                        response.set_status(false, new Error("An unknown error occurred"))
                    }
                }else{
                    response.set_status(false, new Error("This email does not exist"))
                }
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        }
    }
}