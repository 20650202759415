import {
    firestore,
    userStore,
} from '../../config/firebase';
import globalObjects from "../../utils/globalObjects";
import firebase from "../../config/firebase";
import User from "../../models/user";
import Plans from "../../utils/Plans";

export default {
    namespaced: true,
    state: {
        user: {id: '', data: {}},
        all_users: [],
        listener: null,
        all_users_listener: null,
    },
    getters: {
        getUser: (state)=> state.user,
        getAllUsers: (state)=> state.all_users,
    },
    mutations: {
        setUser: (state, payload) => state.user = payload,
        setAllUsers: (state, payload) => state.all_users = payload,
        reset: (state) => {
            if(state.listener !== null){
                state.listener()
            }
            if(state.all_users_listener !== null){
                state.all_users_listener();
            }
            state.listener = state.all_users_listener = null;
            state.user = {id: '', data: {}}
        }
    },
    actions: {
        async getUser(context, user_id){
            const response = new globalObjects.CustomResponse();
            try{
                let userDoc = await userStore.doc(user_id).get();
                if(userDoc.exists){
                    response.data = {
                        id: userDoc.id,
                        data: userDoc.data()
                    }
                }else{
                    response.set_status(false)
                }
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async getUserByEmail(context, email){
            const response = new globalObjects.CustomResponse();
            try{
                let userDoc = await userStore
                    .where('email', '==', email)
                    .limit(1)
                    .get();
                if(!userDoc.empty){
                    response.data = {
                        id: userDoc.docs[0].id,
                        data: userDoc.docs[0].data()
                    }
                }else{
                    response.set_status(false)
                }
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async fetchAllUsers({state, commit}){
            state.all_users_listener = userStore
                .orderBy('createdAt', 'desc')
                .onSnapshot(snapshot => {
                    let tmp_arr = [];
                    snapshot.forEach(doc=>{
                        if(doc.exists){
                            tmp_arr.push({
                                id: doc.id,
                                data: doc.data()
                            })
                        }
                    });
                    commit('setAllUsers', tmp_arr)
                })
        },
        async initUser({state, commit}, user_id){
            state.listener = userStore.doc(user_id).onSnapshot(doc=>{
                if(doc.exists){
                    commit('setUser', {
                        id: doc.id,
                        data: doc.data()
                    })
                }
            });
            return Promise.resolve(null)
        },
        async updateUser(context, {userId, userData}){
            const response = new globalObjects.CustomResponse();
            try{
                delete userData.createdAt;
                userData.updatedAt = firestore.FieldValue.serverTimestamp();
                await userStore.doc(userId).update(Object.assign({}, userData))
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async addUserWallet({rootGetters}, {wallet_type, wallet_address}){
            const response = new globalObjects.CustomResponse();
            const user = rootGetters['user/getUser'];
            try{
                const u_data = {};
                u_data[`wallets.${wallet_type}`] = firestore.FieldValue.arrayUnion(wallet_address)
                await userStore.doc(user.id).update(u_data)
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async removeUserWallet({rootGetters}, {wallet_type, wallet_address}){
            const response = new globalObjects.CustomResponse();
            const user = rootGetters['user/getUser'];
            try{
                const u_data = {};
                u_data[`wallets.${wallet_type}`] = firestore.FieldValue.arrayRemove(wallet_address)
                await userStore.doc(user.id).update(u_data)
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async updateUserPassword(context, password){
            const response = new globalObjects.CustomResponse();
            try{
                const user = firebase.auth().currentUser;
                await user.updatePassword(password)
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async creditReferralBonus({rootGetters, dispatch}, depositInstance){
            const response = new globalObjects.CustomResponse();
            response.data.referralBonus = 0;
            try{
                const user =  await dispatch('getUser', rootGetters['user/getUser'].id);
                if(user.status){
                    const usr = new User(user.data.data, user.data.id);
                    if(usr.data.referredBy){
                        const referee = await userStore.doc(this.data.referredBy).get();
                        if(referee.exists){
                            const plan = Plans.all[depositInstance.data.plan][depositInstance.data.package];
                            response.data.referralBonus = (plan.referralBonus/100)*depositInstance.data.amountDeposited
                            const balance = (referee.data().balance+response.data.referralBonus);
                            await userStore.doc(this.data.referredBy).update({balance});
                            await dispatch('bonus/addBonus', {
                                user: usr,
                                amount: response.data.referralBonus
                            }, {root: true})
                        }
                    }
                }
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        }
    }
}