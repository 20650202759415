import {depositStore, firestore, userStore} from "../config/firebase";
import Plans from "../utils/Plans";

class Deposit{
    constructor(deposit={}, deposit_id='') {
        this.id = deposit_id;
        this.data = Object.assign(new Model(), deposit)
    }

    async autoTrade(){
        try{
            const plan = Plans.all[this.data.plan][this.data.package];
            let now = (new Date()).getTime();
            let prev = this.data.createdAt.toDate().getTime();
            let prev2 = this.data.updatedAt.toDate().getTime();
            let day_diff = Number.parseInt(((now - prev)/(1000*60*60*24)));
            let day_diff2 = Number.parseInt(((now - prev2)/(1000*60*60*24)));
            let depositUpdate = {};
            let userUpdate = {};

            // Weekly earnings till lifespan
            if(!plan.accumulation.status){
                if(day_diff <= (Number.parseInt(plan.lifeSpan*30.4167))){    // 30.4167 days = 1 month
                    if(day_diff2 >= 7){
                        for(let i=0; i<day_diff2; i++){
                            this.data.amountEarned += (plan.dailyProfit * this.data.amountDeposited)
                        }
                        depositUpdate.updatedAt = firestore.FieldValue.serverTimestamp();
                        depositUpdate.amountEarned = this.data.amountEarned;
                    }
                }else{
                    depositUpdate.status = 'FILLED';
                    userUpdate.balance = depositUpdate.amountEarned||0;
                }
            }else{
                // Daily interest till days interval elapses
                if(day_diff2 >= 1 && day_diff <= plan.accumulation.frequency){
                    for(let i=0; i<day_diff2; i++){
                        this.data.amountEarned += (plan.dailyProfit * this.data.amountDeposited)
                    }
                    depositUpdate.updatedAt = firestore.FieldValue.serverTimestamp();
                    depositUpdate.amountEarned = this.data.amountEarned;
                }else if(day_diff2 > plan.accumulation.frequency){
                    depositUpdate.status = 'FILLED';
                    userUpdate.balance = depositUpdate.amountEarned||0;
                }
            }
            if(Object.entries(depositUpdate).length > 0){
                await depositStore.doc(this.id).update(depositUpdate)
            }
            if(Object.entries(userUpdate).length > 0){
                const user = await userStore.doc(this.data.user).get();
                if(user.exists){
                    await userStore.doc(user.id).update({
                        balance: Math.max(0, (user.data().balance+userUpdate.balance))
                    })
                }
            }
        }catch (e){
            console.log(e)
        }
        return Promise.resolve(null)
    }
}

function Model(){
    this.user = '';
    this.plan = '';     // bitcoin, etc
    this.package = '';     // basic, prime etc
    this.amountDeposited = 0;
    this.status = 'PENDING';    // PENDING, IN-PROGRESS, FILLED, DECLINED
    this.currency = 'USD';
    this.paidThrough = 'WALLET';    // WALLET, BALANCE
    this.walletAddress = '';
    this.walletAddressOwner = '';   // user-id (admin)
    this.amountEarned = 0;
    this.createdAt = firestore.FieldValue.serverTimestamp();
    this.updatedAt = firestore.FieldValue.serverTimestamp();
}

export default Deposit