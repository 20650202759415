<template>
  <div id="app">
    <router-view/>
    <div class="position-fixed" :style="{bottom: '50px',right: '0', zIndex: '5'}">
      <button class="btn btn-light">
        <div id="google_translate_element"></div>
      </button>
    </div>
  </div>
</template>

<style>

</style>
<script lang="js">
export default {
  name: 'App',
  mounted() {
    const _script = document.createElement('script');
    _script.type = 'text/javascript';
    _script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    _script.id = 'google_translate_element_script';
    if(!document.getElementById(_script.id)){
      document.body.appendChild(_script);
    }
  }
}
</script>