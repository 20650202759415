import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from "../config/firebase";
import store from "../store/index"

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: ()=>import("../views/home/Container"),
        children: [
            {
                path: '',
                name: 'Home',
                component: ()=>import("../views/home/Home")
            },
            {
                path: '/about',
                name: 'About',
                component: ()=>import("../views/home/About")
            },
            {
                path: '/faq',
                name: 'FAQ',
                component: ()=>import("../views/home/Faq")
            },
            {
                path: '/privacy-policy',
                name: 'PrivacyPolicy',
                component: ()=>import("../views/home/Privacy")
            },
            {
                path: '/legal',
                name: 'Legal',
                component: ()=>import("../views/home/Legal")
            },
            {
                path: '/contact',
                name: 'Contact',
                component: ()=>import("../views/home/Contact")
            },
            {
                path: '/bitcoin',
                name: 'BitcoinInv',
                component: ()=>import("../views/home/investments/Bitcoin")
            },
            {
                path: '/gold',
                name: 'GoldInv',
                component: ()=>import("../views/home/investments/Gold")
            },
            {
                path: '/ethereum',
                name: 'EthereumInv',
                component: ()=>import("../views/home/investments/Ethereum")
            },
            {
                path: '/real-estate',
                name: 'RealEstate',
                component: ()=>import("../views/home/estate/Estates")
            },
            {
                path: '/real-estate/47927483',
                name: 'RealEstate1',
                component: ()=>import("../views/home/estate/EstateDetail1")
            },
            {
                path: '/real-estate/527270',
                name: 'RealEstate2',
                component: ()=>import("../views/home/estate/EstateDetail2")
            },
            {
                path: '/real-estate/527263',
                name: 'RealEstate3',
                component: ()=>import("../views/home/estate/EstateDetail3")
            },
        ]
    },
    {
        path: '/auth',
        component: ()=>import("../views/auth/Container"),
        children: [
            {
                path: '',
                name: 'ALogin',
                component: ()=>import("../views/auth/Login")
            },
            {
                path: '/register',
                name: 'Register',
                component: ()=>import("../views/auth/Register")
            },
            {
                path: '/reset-password',
                name: 'ResetPassword',
                component: ()=>import("../views/auth/ResetPassword")
            },
            {
                path: '/password-reset/:email/:passRef',
                name: 'PasswordReset',
                component: ()=>import('../views/auth/ConfirmResetPassword')
            },
            {
                path: '/register/:referral_id',
                name: 'RegisterRef',
                component: ()=>import("../views/auth/Register")
            },
            {
                path: '/login',
                name: 'Login',
                component: ()=>import("../views/auth/Login")
            },
        ]
    },
    {
        path: '/dashboard',
        component: ()=>import("../views/dashboard/Container"),
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'Dashboard',
                component: ()=>import("../views/dashboard/dashboard")
            },
            // Deposit
            {
                path: '/deposit',
                name: 'Deposit',
                component: ()=>import("../views/dashboard/deposit/Deposit")
            },
            {
                path: '/deposit-list',
                name: 'DepositList',
                component: ()=>import("../views/dashboard/deposit/DepositList")
            },
            // Withdrawal
            {
                path: '/withdraw',
                name: 'Withdraw',
                component: ()=>import("../views/dashboard/withdrawal/Withdrawal")
            },
            {
                path: '/withdrawal-list',
                name: 'Withdrawals',
                component: ()=>import("../views/dashboard/withdrawal/WithdrawalList")
            },
            // Transfer
            {
                path: '/transfer',
                name: 'Transfer',
                component: ()=>import("../views/dashboard/transfer/Transfer")
            },
            {
                path: '/transfer-list',
                name: 'Transfers',
                component: ()=>import("../views/dashboard/transfer/TransferList")
            },
            //Referral
            {
                path: '/referrals',
                name: 'Referrals',
                component: ()=>import("../views/dashboard/Referral")
            },
            //Profile
            {
                path: '/profile',
                name: 'Profile',
                component: ()=>import("../views/dashboard/Profile")
            },
            /**
             * Admin Pages
             */
            {
                path: '/users',
                name: 'Users',
                component: ()=>import("../views/dashboard/users/Users"),
                meta: {
                    requiresAdmin: true
                },
            },
            {
                path: '/deposits',
                name: 'Deposits',
                component: ()=>import("../views/dashboard/deposit/AllDeposit"),
                meta: {
                    requiresAdmin: true
                },
            },
            {
                path: '/withdrawals',
                name: 'AllWithdrawals',
                component: ()=>import("../views/dashboard/withdrawal/AllWithdrawals"),
                meta: {
                    requiresAdmin: true
                },
            },
            {
                path: '/contacts',
                name: 'AllContacts',
                component: ()=>import("../views/dashboard/contact/AllContacts"),
                meta: {
                    requiresAdmin: true
                },
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


router.beforeEach(async (to, from, next) => {
    let loggedUser = firebase.auth().currentUser;
    let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    let requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
    if(requiresAuth && !loggedUser) next({name: 'Login'});
    else if(requiresAdmin && !store.getters['user/getUser'].data.is_admin) next({name:'Dashboard'})
    else next()
    next()
});

router.afterEach(()=>{
    $('html, body').animate({
        scrollTop: 0
    }, 1000);
})

export default router
