import {contactStore} from "../../config/firebase";
import globalObjects from "../../utils/globalObjects";
import Contact from "../../models/contact";

export default {
    namespaced: true,
    state: {
        contacts: [],
        listener: null
    },
    getters: {
        getContacts: state => state.contacts,
    },
    mutations: {
        setContacts: (state, payload) => state.contacts=payload,
        reset(state){
            if(state.listener!==null) state.listener();
            state.listener = null;
            state.contacts = [];
        }
    },
    actions: {
        fetchContacts({state, commit}){
            state.listener = contactStore
                .onSnapshot(snapshot => {
                    const tmp_arr = [];
                    snapshot.forEach(doc=>{
                        if(doc.exists){
                            tmp_arr.push({id: doc.id, data: doc.data()})
                        }
                    });
                    commit('setContacts', tmp_arr)
                })
        },
        async addContact(context, contact){
            const response = new globalObjects.CustomResponse();
            try{
                const new_contact = new Contact(contact);
                await contactStore.add(Object.assign({}, new_contact.data))
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        }
    }
}