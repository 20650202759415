import {firestore, walletStore} from "../../config/firebase";
import globalObjects from "../../utils/globalObjects";

export default {
    namespaced: true,
    state: {
        wallets: [],
        listener: null
    },
    getters: {
        getWallets: state => state.wallets
    },
    mutations: {
        setWallets: (state, payload) => state.wallets = payload,
        reset(state){
            if(state.listener !== null) state.listener();
            state.wallets = [];
            state.listener = null;
        }
    },
    actions: {
        fetchWallets({state, commit}){
            state.listener = walletStore
                .onSnapshot(snapshot => {
                    const tmp_arr = [];
                    snapshot.forEach(doc=>{
                        if(doc.exists){
                            tmp_arr.push({
                                id: doc.id,
                                data: doc.data()
                            })
                        }
                    });
                    commit('setWallets', tmp_arr)
                })
        },
        async getAddress(){
            const response = new globalObjects.CustomResponse();
            response.data.address = '';
            try{
                const addressDoc = await walletStore.doc('BTC').get();
                if(addressDoc.exists){
                    const addresses = addressDoc.data().addresses;
                    if(addresses.length > 0){
                        response.data.address = addresses[Math.floor(Math.random()*(addresses.length-1))]
                        response.data.createdBy = addressDoc.data().user;
                    }else{
                        response.set_status(false, new Error("Wallet empty"))
                    }
                }else{
                    response.set_status(false, new Error("Wallet empty"))
                }
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async addWallet({rootGetters}, {wallet_type, wallet_address}){
            const response = new globalObjects.CustomResponse();
            const user = rootGetters['user/getUser'];
            try{
                const u_data = {};
                u_data.addresses = firestore.FieldValue.arrayUnion(wallet_address);
                const w_doc = await walletStore.doc(wallet_type).get();
                if(w_doc.exists){
                    await walletStore.doc(wallet_type).update(u_data)
                }else{
                    u_data.user = user.id;
                    await walletStore.doc(wallet_type).set(u_data)
                }

            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async removeWallet(context, {wallet_type, wallet_address}){
            const response = new globalObjects.CustomResponse();
            try{
                await walletStore.doc(wallet_type).update({
                    addresses: firestore.FieldValue.arrayRemove(wallet_address)
                })
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
    }
}